import React, { useState, useEffect } from 'react';
import './App.css';
import ControlPanel from './pages/colas/ControlPanel';
import TodayPublications from './pages/publicaciones/TodayPublications';
import TokenUpdater from './pages/token/TokenUpdater';
import LoginPage from './pages/login/LoginPage';
import UserBehaviorAnalytics from './pages/comportamiento/UserBehaviorAnalytics';
import ManagePublications from './pages/pub_manager/ManagePublications';
import ProcessingTimeAnalytics from './pages/analytics/ProcessingTimeAnalytics'; // Importa el nuevo componente

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState('controlPanel');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const username = localStorage.getItem('username');

    // Si hay un token y un nombre de usuario en localStorage, consideramos que el usuario está autenticado
    if (accessToken && username) {
      setIsAuthenticated(true);

      // Comprueba si el usuario es administrador
      if (username === 'rafa') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  if (!isAuthenticated) {
    return <LoginPage onLogin={setIsAuthenticated} />;
  }

  return (
    <div className="app-container" style={styles.appContainer}>
      <header style={styles.header}>
        <h1 style={styles.title}>Xbot Panel de Control</h1>
        <button
          style={styles.logoutButton}
          onClick={handleLogout}
        >
          <i className="fas fa-sign-out-alt" style={styles.icon}></i> Logout
        </button>
      </header>

      <nav style={styles.nav}>
        <ul style={styles.navList}>
          <li
            style={selectedComponent === 'controlPanel' ? styles.activeNavItem : styles.navItem}
            onClick={() => setSelectedComponent('controlPanel')}
          >
            Estado de las colas
          </li>
          <li
            style={selectedComponent === 'todayPublications' ? styles.activeNavItem : styles.navItem}
            onClick={() => setSelectedComponent('todayPublications')}
          >
            Publicaciones de Hoy
          </li>
          <li
            style={selectedComponent === 'userBehaviorAnalytics' ? styles.activeNavItem : styles.navItem}
            onClick={() => setSelectedComponent('userBehaviorAnalytics')}
          >
            Análisis de Comportamiento
          </li>
          <li
            style={selectedComponent === 'managePublications' ? styles.activeNavItem : styles.navItem}
            onClick={() => setSelectedComponent('managePublications')}
          >
            Gestionar Publicaciones
          </li>
          <li
                style={selectedComponent === 'processingTimeAnalytics' ? styles.activeNavItem : styles.navItem}
                onClick={() => setSelectedComponent('processingTimeAnalytics')}
              >
                Análisis de Tiempos de Procesamiento
          </li>

          {isAdmin && (
            <>
              <li
                style={selectedComponent === 'tokenUpdater' ? styles.activeAdminNavItem : styles.adminNavItem}
                onClick={() => setSelectedComponent('tokenUpdater')}
              >
                Actualizar Token
              </li>
            </>
          )}
        </ul>
      </nav>

      <div className="App" style={styles.content}>
        {selectedComponent === 'controlPanel' && <ControlPanel />}
        {selectedComponent === 'todayPublications' && <TodayPublications />}
        {selectedComponent === 'userBehaviorAnalytics' && <UserBehaviorAnalytics />}
        {selectedComponent === 'managePublications' && <ManagePublications isAdmin={isAdmin} />}
        {selectedComponent === 'processingTimeAnalytics' && isAdmin && <ProcessingTimeAnalytics />}  {/* Componente nuevo */}
        {selectedComponent === 'tokenUpdater' && isAdmin && <TokenUpdater />}
      </div>
    </div>
  );
}

const styles = {
  appContainer: {
    width: '100%',
    maxWidth: '1200px',
    margin: '40px auto',
    padding: '20px',
    boxSizing: 'border-box',
    backgroundColor: '#f7f9fc',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
  },
  title: {
    margin: 0,
    fontSize: '1.8rem',
    color: '#333',
  },
  nav: {
    borderBottom: '1px solid #ddd',
    marginBottom: '30px',
  },
  navList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  navItem: {
    padding: '10px 15px',
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    fontWeight: 'bold',
    color: '#007bff',
    transition: 'color 0.3s ease, border-bottom 0.3s ease',
    textAlign: 'center',
    flex: '1 1 auto',
  },
  activeNavItem: {
    padding: '10px 15px',
    cursor: 'pointer',
    borderBottom: '2px solid #007bff',
    fontWeight: 'bold',
    color: '#0056b3',
    textAlign: 'center',
    flex: '1 1 auto',
  },
  adminNavItem: {
    padding: '10px 15px',
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    fontWeight: 'bold',
    color: '#ffa500',
    transition: 'color 0.3s ease, border-bottom 0.3s ease',
    textAlign: 'center',
    flex: '1 1 auto',
  },
  activeAdminNavItem: {
    padding: '10px 15px',
    cursor: 'pointer',
    borderBottom: '2px solid #ff8c00',
    fontWeight: 'bold',
    color: '#ff8c00',
    textAlign: 'center',
    flex: '1 1 auto',
  },
  logoutButton: {
    backgroundColor: '#f64e60',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
  },
  icon: {
    marginRight: '8px',
  },
  content: {
    width: '100%',
  },
};

export default App;
