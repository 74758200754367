import React, { useState } from 'react';

const ModalCreatePublication = ({ isOpen, onClose, onCreate }) => {
  const [cid, setCid] = useState('');
  const [canonicalName, setCanonicalName] = useState('');
  const [source, setSource] = useState('manual');
  const [account, setAccount] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [publicationType, setPublicationType] = useState('prensa');
  
  // Inicializar 'weekdays' con los valores predeterminados 1 a 7 (Lunes a Domingo)
  const [weekdays, setWeekdays] = useState([1, 2, 3, 4, 5, 6, 7]);
  
  // Inicializar 'nthweek' con los valores predeterminados 1 a 5
  const [nthweek, setNthweek] = useState([1, 2, 3, 4, 5]);

  // Estado para manejar mensajes de error
  const [error, setError] = useState({ cid: '', canonicalName: '', source: '' });

  const handleSubmit = () => {
    let isValid = true;
    const newError = { cid: '', canonicalName: '', source: '' };

    // Validar CID
    if (!cid.trim()) {
      newError.cid = 'El CID es requerido.';
      isValid = false;
    }

    // Validar Canonical Name
    if (!canonicalName.trim()) {
      newError.canonicalName = 'El nombre canónico es requerido.';
      isValid = false;
    }

    // Validar Source
    if (!source.trim()) {
      newError.source = 'El source es requerido.';
      isValid = false;
    }

    // Actualizar estado de error
    setError(newError);

    // Si todo es válido, proceder con la creación
    if (isValid) {
      onCreate({
        cid,
        canonical_name: canonicalName,
        source,
        account,
        is_active: isActive,
        type: publicationType,
        weekdays,
        nthweek,
      });
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <style>
        {`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-content {
            background: white;
            padding: 20px;
            border-radius: 8px;
            max-width: 500px;
            width: 100%;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            position: relative;
          }

          .modal-content h2 {
            margin-bottom: 20px;
            font-size: 1.5rem;
          }

          .modal-content input, .modal-content select {
            padding: 10px;
            width: calc(100% - 20px);
            font-size: 1rem;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-bottom: 10px;
          }

          .modal-content .modal-actions {
            display: flex;
            justify-content: space-between;
          }

          .modal-content .modal-actions button {
            padding: 10px 20px;
            border-radius: 4px;
            font-size: 1rem;
            cursor: pointer;
            border: none;
          }

          .modal-content .modal-actions .create-button {
            background-color: #007bff;
            color: white;
          }

          .modal-content .modal-actions .cancel-button {
            background-color: #dc3545;
            color: white;
          }

          .error-message {
            color: red;
            font-size: 0.9rem;
            margin-bottom: 10px;
            text-align: left;
          }

          .optional-label {
            font-size: 0.9rem;
            color: #888;
            margin-bottom: 5px;
          }
        `}
      </style>

      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Crear Nueva Publicación</h2>

          {/* CID input */}
          <input
            type="text"
            placeholder="CID (requerido)"
            value={cid}
            onChange={(e) => setCid(e.target.value)}
          />
          {error.cid && <p className="error-message">{error.cid}</p>}

          {/* Canonical Name input */}
          <input
            type="text"
            placeholder="Canonical Name (requerido)"
            value={canonicalName}
            onChange={(e) => setCanonicalName(e.target.value)}
          />
          {error.canonicalName && <p className="error-message">{error.canonicalName}</p>}

          {/* Source select */}
          <select value={source} onChange={(e) => setSource(e.target.value)}>
            <option value="manual">manual</option>
            <option value="pressreader">pressreader</option>
            <option value="kioskoymas">kioskoymas</option>
            <option value="orbyt">orbyt</option>
          </select>
          {error.source && <p className="error-message">{error.source}</p>}

          {/* Account input (opcional) */}
          <div className="optional-label">Account (opcional)</div>
          <input
            type="text"
            placeholder="Account (opcional)"
            value={account}
            onChange={(e) => setAccount(e.target.value)}
          />

          {/* Activo checkbox */}
          <div>
            <label>
              <input
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
              Activo
            </label>
          </div>

          <div className="modal-actions">
            <button className="create-button" onClick={handleSubmit}>
              Crear
            </button>
            <button className="cancel-button" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCreatePublication;
