import React, { useState, useEffect } from 'react';
import './ControlPanel.css';
import QueueActionButtons from './QueueActionButtons';
import QueuePeekMessages from './QueuePeekMessages';
import QueuePeekScrapers from './QueuePeekScrapers';

const ControlPanel = () => {
  const [queuesStatus, setQueuesStatus] = useState({
    scraping_queue_press: "",
    scraping_queue_mag: "",
    scraping_retries_queue_press: "",
    scraping_retries_queue_mag: "",
  });

  const fetchQueueStatus = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/rabbitmq_count', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      // Solo actualiza el estado si el valor no es "-"
      setQueuesStatus((prevState) => ({
        prensa: data.prensa !== "-" ? data.prensa : prevState.prensa,
        revistas: data.revistas !== "-" ? data.revistas : prevState.revistas,
        scraping_queue_press: data.scraping_queue_press !== "-" ? data.scraping_queue_press : prevState.scraping_queue_press,
        scraping_queue_mag: data.scraping_queue_mag !== "-" ? data.scraping_queue_mag : prevState.scraping_queue_mag,
        scraping_retries_queue_press: data.scraping_retries_queue_press !== "-" ? data.scraping_retries_queue_press : prevState.scraping_retries_queue_press,
        scraping_retries_queue_mag: data.scraping_retries_queue_mag !== "-" ? data.scraping_retries_queue_mag : prevState.scraping_retries_queue_mag,
      }));
    } catch (error) {
      console.error("Error fetching queue status:", error);
    }
  };

  useEffect(() => {
    fetchQueueStatus();

    const intervalId = setInterval(() => {
      if (document.visibilityState === 'visible') {
        fetchQueueStatus();
      }
    }, 10000); // Intervalo de 10 segundos

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const applyTransitionEffect = (selector) => {
      const element = document.querySelector(selector);
      if (element) {
        element.classList.add('counter-change');
        setTimeout(() => {
          element.classList.remove('counter-change');
        }, 300);
      }
    };

    applyTransitionEffect('.prensa-counter');
    applyTransitionEffect('.revistas-counter');
    applyTransitionEffect('.scraping-queue-press-counter');
    applyTransitionEffect('.scraping-retries-press-counter');
    applyTransitionEffect('.scraping-queue-mag-counter');
    applyTransitionEffect('.scraping-retries-mag-counter');
  }, [queuesStatus]);

  const purgeQueue = async (queueName) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/rabbitmq_purge', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ queue_name: queueName }),
      });

      const result = await response.json();
      console.log(result.message || result.error);
      fetchQueueStatus();
    } catch (error) {
      console.error("Error purging queue:", error);
    }
  };

  return (
    <div className="control-panel-container">
      <div className="title-container">
        <h1 className="control-panel-title">Estado de las colas</h1>
        <i
          className="fas fa-sync-alt refresh-icon"
          onClick={fetchQueueStatus}
          title="Refrescar"
        ></i>
      </div>

      {/* Sección de colas */}
      <div className="queue-section">
        <h3 className="queue-section-title">
          Clicks pendientes Prensa
        </h3>
        <div className="queue-section-content">
          <span className="counter prensa-counter">
            {queuesStatus.prensa}
          </span>
          <button
            onClick={() => purgeQueue('prensa')}
            className="queue-action-button"
          >
            Limpiar Cola
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>

      <div className="queue-section">
        <h3 className="queue-section-title">
          Clicks pendientes Revistas
        </h3>
        <div className="queue-section-content">
          <span className="counter revistas-counter">
            {queuesStatus.revistas}
          </span>
          <button
            onClick={() => purgeQueue('revistas')}
            className="queue-action-button"
          >
            Limpiar Cola
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>

      <div className="queue-section">
        <h3 className="queue-section-title">
          Cola de Scraping Prensa (Listos)
        </h3>
        <div className="queue-section-content">
          <span className="counter scraping-queue-press-counter">
            {queuesStatus.scraping_queue_press}
          </span>
          <button
            onClick={() => purgeQueue('scraping_queue_press')}
            className="queue-action-button"
          >
            Limpiar Cola
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>

      <div className="queue-section">
        <h3 className="queue-section-title">
          Cola de Scraping Revistas (Listos)
        </h3>
        <div className="queue-section-content">
          <span className="counter scraping-queue-mag-counter">
            {queuesStatus.scraping_queue_mag}
          </span>
          <button
            onClick={() => purgeQueue('scraping_queue_mag')}
            className="queue-action-button"
          >
            Limpiar Cola
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>

      <div className="queue-section">
        <h3 className="queue-section-title">
          Cola de Reintentos Prensa (Scraping Fallidos)
        </h3>
        <div className="queue-section-content">
          <span className="counter scraping-retries-press-counter">
            {queuesStatus.scraping_retries_queue_press}
          </span>
          <button
            onClick={() => purgeQueue('scraping_retries_queue_press')}
            className="queue-action-button"
          >
            Limpiar Cola
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>

      <div className="queue-section">
        <h3 className="queue-section-title">
          Cola de Reintentos Revistas (Scraping Fallidos)
        </h3>
        <div className="queue-section-content">
          <span className="counter scraping-retries-mag-counter">
            {queuesStatus.scraping_retries_queue_mag}
          </span>
          <button
            onClick={() => purgeQueue('scraping_retries_queue_mag')}
            className="queue-action-button"
          >
            Limpiar Cola
            <i className="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>

      {/* Colocando los botones debajo de las colas */}
      <QueueActionButtons fetchQueueStatus={fetchQueueStatus} />

      {/* Colocando los peeks debajo de los botones */}
      <QueuePeekMessages queueName="prensa" maxMessages={200} />
      <QueuePeekMessages queueName="revistas" maxMessages={200} />
      <QueuePeekScrapers queueName="scraping_queue_press" maxMessages={200} />
      <QueuePeekScrapers queueName="scraping_queue_mag" maxMessages={200} />
      <QueuePeekScrapers queueName="scraping_retries_queue_press" maxMessages={200} />
      <QueuePeekScrapers queueName="scraping_retries_queue_mag" maxMessages={200} />

    </div>
  );
};

export default ControlPanel;
