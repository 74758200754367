import React from 'react';

const WeekdaySelector = ({ selectedDays, canonicalName, onChange }) => {
  const weekdays = ['L', 'M', 'X', 'J', 'V', 'S', 'D']; // Representación de Lunes a Domingo

  const toggleDay = async (dayIndex) => {
    const newSelectedDays = [...selectedDays];
    if (newSelectedDays.includes(dayIndex + 1)) {
      // Si el día ya está seleccionado, lo quitamos
      const index = newSelectedDays.indexOf(dayIndex + 1);
      newSelectedDays.splice(index, 1);
    } else {
      // Si el día no está seleccionado, lo añadimos
      newSelectedDays.push(dayIndex + 1);
    }

    // Actualizamos el estado en el padre
    onChange(newSelectedDays.sort());

    // Realizamos la petición al backend para actualizar
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/publication/schedule', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          canonical_name: canonicalName,
          weekdays: newSelectedDays,
          ntweek: [], // Si solo estás actualizando weekdays, ntweek puede ser el valor actual o vacío
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update weekdays');
      }
    } catch (error) {
      console.error('Error updating weekdays:', error);
    }
  };

  // Estilos en línea
  const styles = {
    container: {
      display: 'flex',
      gap: '5px',
    },
    button: {
      backgroundColor: '#f0f0f0',
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '5px 8px',
      cursor: 'pointer',
      color: '#666',
    },
    selectedButton: {
      backgroundColor: '#007bff',
      color: 'white',
      borderColor: '#007bff',
    },
    unselectedButton: {
      backgroundColor: '#e0e0e0',
      color: '#555',
      border: '1px dashed #999',
    }
  };

  return (
    <div style={styles.container}>
      {weekdays.map((day, index) => (
        <button
          key={index}
          style={{
            ...styles.button,
            ...(selectedDays.includes(index + 1) ? styles.selectedButton : styles.unselectedButton)
          }}
          onClick={() => toggleDay(index)}
        >
          {day}
        </button>
      ))}
    </div>
  );
};

export default WeekdaySelector;
