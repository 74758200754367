import React from 'react';

const NthWeekSelector = ({ selectedWeeks, canonicalName, onChange }) => {
  const weeks = [1, 2, 3, 4, 5]; // Representación de las semanas del mes

  const toggleWeek = async (week) => {
    const newSelectedWeeks = [...selectedWeeks];
    if (newSelectedWeeks.includes(week)) {
      const index = newSelectedWeeks.indexOf(week);
      newSelectedWeeks.splice(index, 1);
    } else {
      newSelectedWeeks.push(week);
    }

    // Actualizamos el estado en el padre
    onChange(newSelectedWeeks.sort());

    // Realizamos la petición al backend para actualizar
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/publication/schedule', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          canonical_name: canonicalName,
          weekdays: [], // Si solo estás actualizando ntweek, weekdays puede ser el valor actual o vacío
          ntweek: newSelectedWeeks,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update ntweek');
      }
    } catch (error) {
      console.error('Error updating ntweek:', error);
    }
  };

  return (
    <>
      <style>
        {`
          .nthweek-selector {
            display: flex;
            gap: 5px;
            flex-wrap: nowrap;
            justify-content: center;
          }

          .nthweek-button {
            width: 30px;
            height: 30px;
            border-radius: 4px;
            font-size: 1rem;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            background-color: #28a745;
            color: white;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .nthweek-button:hover {
            background-color: #218838;
          }

          .unselected-button {
            background-color: #e0e0e0;
            color: #555;
            border: '1px dashed #999';
          }
        `}
      </style>
      <div className="nthweek-selector">
        {weeks.map((week, index) => (
          <button
            key={index}
            className={`nthweek-button ${selectedWeeks.includes(week) ? '' : 'unselected-button'}`}
            onClick={() => toggleWeek(week)}
          >
            {week}
          </button>
        ))}
      </div>
    </>
  );
};

export default NthWeekSelector;
