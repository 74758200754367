import React, { useState, useEffect } from 'react';
import './ManagePublications.css';
import WeekdaySelector from './WeekdaySelector';
import NthWeekSelector from './NthWeekSelector';
import ModalCreatePublication from './ModalCreatePublication';
import ModalEditCid from './ModalEditCid';
import ExportCSVButton from './ExportCSVButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faCheckCircle, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';  // Íconos

const ManagePublications = ({ isAdmin }) => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCreateOpen, setModalCreateOpen] = useState(false);
  const [currentCID, setCurrentCID] = useState('');
  const [currentCanonicalName, setCurrentCanonicalName] = useState('');

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  // Nuevo estado para controlar el ícono de recarga y el mensaje de error
  const [reloadStatus, setReloadStatus] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/publications', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('API Data:', data);

        const formattedData = data.map(pub => ({
          ...pub,
          weekdays: Array.isArray(pub.weekdays) ? pub.weekdays : [],
          nthweek: Array.isArray(pub.nthweek) ? pub.nthweek : []  // Asegura que nthweek siempre sea un array
        }));

        setPublications(formattedData);
      } catch (error) {
        setError('No se pudieron cargar las publicaciones. Intenta nuevamente más tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchPublications();
  }, []);

  const handleDeletePublication = async (canonicalName) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar esta publicación?');
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`https://telegram-delivery-s3.herokuapp.com/api/publication/delete/${canonicalName}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error al eliminar la publicación');
      }

      // Actualizar el estado local eliminando la publicación
      setPublications((prev) => prev.filter((pub) => pub.canonical_name !== canonicalName));
    } catch (error) {
      console.error('Error al eliminar la publicación:', error);
    }
  };

  const handleCreatePublication = async (newPublication) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/publication/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newPublication),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al crear la publicación');
      }
  
      // Obtener la respuesta JSON del backend
      const result = await response.json();
      
      // Aquí obtenemos la publicación creada desde la respuesta bajo la clave "publication"
      if (result.publication) {
        const createdPub = result.publication;
  
        // Añadimos la nueva publicación al estado
        setPublications(prev => [
          ...prev,
          {
            ...createdPub,
            weekdays: Array.isArray(createdPub.weekdays) ? createdPub.weekdays : [1, 2, 3, 4, 5, 6, 7],
            nthweek: Array.isArray(createdPub.nthweek) ? createdPub.nthweek : [1, 2, 3, 4, 5],
          }
        ]);
        
        // Cierra el modal después de que se haya creado la publicación correctamente
        setModalCreateOpen(false);
      } else {
        throw new Error('Error al crear la publicación: No se devolvió un objeto de publicación.');
      }
  
    } catch (error) {
      console.error('Error al crear la publicación:', error.message || error);
      alert(error.message || 'Se produjo un error inesperado. Inténtalo de nuevo.');
    }
  };
    

  const handleToggleType = async (canonicalName, currentType) => {
    const newType = currentType === 'prensa' ? 'revistas' : 'prensa';
    setIsUpdating(true);
    try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/publication/type', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ canonical_name: canonicalName, type: newType }),
        });

        if (!response.ok) {
            throw new Error('Failed to toggle publication type');
        }

        // Actualiza el tipo en el estado local
        setPublications((prevPublications) =>
            prevPublications.map((pub) =>
                pub.canonical_name === canonicalName ? { ...pub, type: newType } : pub
            )
        );
    } catch (error) {
        setError('No se pudo actualizar el tipo de la publicación. Intenta nuevamente más tarde.');
    } finally {
        setIsUpdating(false);
    }
};

const renderTypeButton = (canonicalName, type) => {
  let typeText;
  if (isSmallScreen) {
    // Mostrar el texto completo en pantallas pequeñas
    typeText = type === 'prensa' ? 'Prensa' : type === 'revistas' ? 'Revistas' : '?';
  } else {
    // Mostrar abreviado en pantallas grandes
    typeText = type === 'prensa' ? 'P' : type === 'revistas' ? 'R' : '?';
  }

  return (
      <button
          className={`type-button ${type === 'prensa' ? 'prensa' : type === 'revistas' ? 'revistas' : 'unknown'}`}
          onClick={() => handleToggleType(canonicalName, type)}
          disabled={isUpdating}
          title={`Cambiar a ${type === 'prensa' ? 'revistas' : 'prensa'}`}
          style={{ textAlign: 'right' }} // Alinear a la derecha
      >
          {typeText}
      </button>
  );
};



  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const handleToggleActive = async (canonicalName, isActive) => {
    setIsUpdating(true);
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/publication/activate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ canonical_name: canonicalName, is_active: !isActive }),
      });

      if (!response.ok) {
        throw new Error('Failed to toggle active status');
      }

      setPublications((prevPublications) =>
        prevPublications.map((pub) =>
          pub.canonical_name === canonicalName ? { ...pub, is_active: !isActive } : pub
        )
      );
    } catch (error) {
      setError('No se pudo actualizar el estado de la publicación. Intenta nuevamente más tarde.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateCID = async (canonicalName, newCID) => {
    setIsUpdating(true);
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/publication/cid', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ canonical_name: canonicalName, new_cid: newCID }),
      });

      if (!response.ok) {
        throw new Error('Failed to update CID');
      }

      setPublications((prevPublications) =>
        prevPublications.map((pub) =>
          pub.canonical_name === canonicalName ? { ...pub, cid: newCID } : pub
        )
      );
    } catch (error) {
      setError('No se pudo actualizar el CID. Intenta nuevamente más tarde.');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCIDClick = (canonicalName, cid) => {
    setCurrentCanonicalName(canonicalName);
    setCurrentCID(cid);
    setModalOpen(true);
  };

  const handleSaveCID = (newCID) => {
    setModalOpen(false);
    handleUpdateCID(currentCanonicalName, newCID);
  };

  const handleReloadPublication = async (canonicalName, source) => {
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/enqueue_message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ canonical_name: canonicalName, source }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to enqueue message');
      }

      setReloadStatus((prevStatus) => ({
        ...prevStatus,
        [canonicalName]: { status: 'success', errorMessage: '' },
      }));
    } catch (error) {
      setReloadStatus((prevStatus) => ({
        ...prevStatus,
        [canonicalName]: { status: 'error', errorMessage: error.message },
      }));
    }
  };

  // Definir handleWeekdaysChange y handleNthWeekChange
  const handleWeekdaysChange = (canonicalName, newWeekdays) => {
    setPublications((prevPublications) =>
      prevPublications.map((pub) =>
        pub.canonical_name === canonicalName ? { ...pub, weekdays: newWeekdays } : pub
      )
    );
  };

  const handleNthWeekChange = (canonicalName, newNthWeeks) => {
    setPublications((prevPublications) =>
      prevPublications.map((pub) =>
        pub.canonical_name === canonicalName ? { ...pub, nthweek: newNthWeeks } : pub
      )
    );
  };

  const getIconForReload = (canonicalName, source) => {
    const status = reloadStatus[canonicalName]?.status;
    const errorMessage = reloadStatus[canonicalName]?.errorMessage;
  
    if (status === 'success') {
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={{ color: 'green', cursor: 'not-allowed' }}
          title="Recarga exitosa"
        />
      );
    } else if (status === 'error') {
      return (
        <FontAwesomeIcon
          icon={faTimesCircle}
          style={{ color: 'red', cursor: 'not-allowed' }}
          title={errorMessage} // Mostrar mensaje de error en el hover
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faSyncAlt}
          className="reload-icon"
          title="Recargar publicación"
          onClick={() => handleReloadPublication(canonicalName, source)}
          style={{ cursor: 'pointer', color: '#007bff' }}
        />
      );
    }
  };
  
  
  const filteredPublications = publications.filter((pub) =>
    pub.canonical_name.toLowerCase().includes(filter)
  );

  return (
    <div className="container">
      <div className="header-container">
        <h1 className="title">Gestionar Publicaciones</h1>
        <ExportCSVButton data={filteredPublications} />
        <button className="create-button" onClick={() => setModalCreateOpen(true)}>
          Crear Publicación
        </button>

      </div>

      {loading ? (
        <div className="loading">Cargando publicaciones...</div>
      ) : error ? (
        <div className="error-container">
          <h2 className="error-message">Lo sentimos...</h2>
          <p className="error-message">{error}</p>
        </div>
      ) : (
        <>
          <div className="filter-container">
            <input
              type="text"
              placeholder="Filtrar por nombre..."
              value={filter}
              onChange={handleFilterChange}
              className="filter-input"
            />
          </div>

          {filteredPublications.length === 0 ? (
            <p className="no-publications">No hay publicaciones que coincidan con el filtro.</p>
          ) : (
            <table className="publication-table">
              <thead>
                <tr>
                  <th>Recargar</th> {/* Nueva columna para el ícono de recarga */}
                  <th>Nombre Canónico</th>
                  <th>CID</th>
                  <th>Source</th>
                  <th>Tipo</th>
                  <th>Activa</th>
                  <th>Días de la Semana</th>
                  <th>Semanas del Mes</th>
                </tr>
              </thead>
              <tbody>
                {filteredPublications.map((pub, index) => {
                  // Mostrar texto completo si es pantalla pequeña
                  const typeText = isSmallScreen ? pub.type : (pub.type === 'prensa' ? 'P' : pub.type === 'revistas' ? 'R' : pub.type); 
                  const sourceDisplay = isSmallScreen 
                    ? pub.source 
                    : ({
                      'manual': '✋',
                      'pressreader': '📗',
                      'kioskoymas': '📕',
                      'orbyt': '📘'
                    }[pub.source] || pub.source);

                  return (
                    <tr key={index}>
                      <td data-label="Recargar">
                        {getIconForReload(pub.canonical_name, pub.source)} {/* Pasa ambos parámetros */}
                      </td>
                      <td data-label="Nombre Canónico">{pub.canonical_name}</td>
                      <td data-label="CID">
                        <span onClick={() => handleCIDClick(pub.canonical_name, pub.cid)}>
                          {pub.cid}
                        </span>
                      </td>
                      <td data-label="Source" title={pub.source}>{sourceDisplay}</td>
                      <td data-label="Tipo" title={pub.type}>{renderTypeButton(pub.canonical_name, pub.type)}</td>
                      <td data-label="Activa">
                        <input
                          type="checkbox"
                          checked={pub.is_active}
                          onChange={() => handleToggleActive(pub.canonical_name, pub.is_active)}
                          disabled={isUpdating}
                        />
                      </td>
                      <td data-label="Días de la Semana">
                        <div className="weekday-selector">
                          <WeekdaySelector
                            selectedDays={pub.weekdays || []}
                            canonicalName={pub.canonical_name}
                            onChange={(newWeekdays) => handleWeekdaysChange(pub.canonical_name, newWeekdays)}
                          />
                        </div>
                      </td>
                      <td data-label="Semanas del Mes">
                        <div className="nthweek-selector">
                          <NthWeekSelector
                            selectedWeeks={pub.nthweek || []}
                            canonicalName={pub.canonical_name}
                            onChange={(newNthWeeks) => handleNthWeekChange(pub.canonical_name, newNthWeeks)}
                          />
                        </div>
                      </td>
                      {isAdmin && (
                        <td data-label="Eliminar">
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ cursor: 'pointer', color: '#dc3545' }}
                            title="Eliminar publicación"
                            onClick={() => handleDeletePublication(pub.canonical_name)}
                          />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </>
      )}

      <ModalCreatePublication
        isOpen={modalCreateOpen}
        onClose={() => setModalCreateOpen(false)}
        onCreate={handleCreatePublication}
      />

      <ModalEditCid
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSaveCID}
        initialValue={currentCID}
      />
    </div>
  );
};

export default ManagePublications;
