import React, { useEffect, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import moment from 'moment-timezone';

const ProcessingTimeAnalytics = () => {
    const [processingData, setProcessingData] = useState([]);
    const [stats, setStats] = useState({});
    const [timeSeriesData, setTimeSeriesData] = useState({});
    const [clickCounts, setClickCounts] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/processing_times', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setProcessingData(data);

                // Calcular la media y los percentiles para cada bot
                const botStats = {};
                const timeSeries = {};
                const botClickCounts = {};

                const percentile = (arr, p) => {
                    if (arr.length === 0) return 0;
                    const sorted = arr.slice().sort((a, b) => a - b);
                    const index = Math.ceil(p * sorted.length) - 1;
                    return sorted[index];
                };

                data.forEach(entry => {
                    const { bot_id, processing_time, datetime } = entry;

                    // Procesar las estadísticas
                    if (!botStats[bot_id]) {
                        botStats[bot_id] = [];
                    }
                    botStats[bot_id].push(processing_time);

                    // Contar clics por bot
                    if (!botClickCounts[bot_id]) {
                        botClickCounts[bot_id] = 0;
                    }
                    botClickCounts[bot_id] += 1;

                    // Procesar los datos de la serie temporal
                    const hour = moment(datetime).tz('Europe/Madrid').hours();
                    if (!timeSeries[bot_id]) {
                        timeSeries[bot_id] = {};
                    }
                    if (!timeSeries[bot_id][hour]) {
                        timeSeries[bot_id][hour] = [];
                    }
                    timeSeries[bot_id][hour].push(processing_time);
                });

                const computedStats = {};
                Object.keys(botStats).forEach(bot_id => {
                    const times = botStats[bot_id];
                    computedStats[bot_id] = {
                        mean: times.reduce((a, b) => a + b, 0) / times.length,
                        p50: percentile(times, 0.50),
                        p90: percentile(times, 0.90),
                        p95: percentile(times, 0.95),
                    };
                });

                const formattedTimeSeries = {};
                Object.keys(timeSeries).forEach(bot_id => {
                    formattedTimeSeries[bot_id] = Array(24).fill(0).map((_, hour) => {
                        const hourTimes = timeSeries[bot_id][hour] || [];
                        return hourTimes.length > 0 ? hourTimes.reduce((a, b) => a + b, 0) / hourTimes.length : 0;
                    });
                });

                setStats(computedStats);
                setTimeSeriesData(formattedTimeSeries);
                setClickCounts(botClickCounts);
            } catch (error) {
                console.error("Error fetching processing data:", error);
            }
        };

        fetchData();
    }, []);

    const botIds = Object.keys(stats);

    const barData = {
        labels: botIds,
        datasets: [
            {
                label: 'Mean Processing Time (s)',
                data: botIds.map(bot_id => stats[bot_id]?.mean),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
            {
                label: '50th Percentile (s)',
                data: botIds.map(bot_id => stats[bot_id]?.p50),
                backgroundColor: 'rgba(153, 102, 255, 0.6)',
            },
            {
                label: '90th Percentile (s)',
                data: botIds.map(bot_id => stats[bot_id]?.p90),
                backgroundColor: 'rgba(255, 159, 64, 0.6)',
            },
            {
                label: '95th Percentile (s)',
                data: botIds.map(bot_id => stats[bot_id]?.p95),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
        ],
    };

    const lineData = {
        labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
        datasets: botIds.map(bot_id => ({
            label: `Bot ${bot_id}`,
            data: timeSeriesData[bot_id],
            fill: false,
            borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`,
        })),
    };

    const pieData = {
        labels: botIds,
        datasets: [
            {
                label: 'Clicks per Bot',
                data: botIds.map(bot_id => clickCounts[bot_id]),
                backgroundColor: botIds.map(() => `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.6)`),
            },
        ],
    };

    return (
        <div>
            <h2>Bot Processing Time Analysis</h2>
            <div>
                <h3>Processing Time Statistics per Bot</h3>
                <Bar data={barData} />
            </div>
            <div>
                <h3>Processing Time Distribution by Hour</h3>
                <Line data={lineData} />
            </div>
            <div>
                <h3>Clicks Distribution per Bot</h3>
                <Pie data={pieData} />
            </div>
        </div>
    );
};

export default ProcessingTimeAnalytics;
