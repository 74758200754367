import React, { useState } from 'react';

const TokenUpdater = () => {
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleTokenChange = (e) => {
    setToken(e.target.value);
  };

  const handleUpdateToken = async () => {
    // Mostrar mensaje de confirmación antes de proceder
    const isConfirmed = window.confirm('¿Estás seguro de que deseas actualizar el token?');
    if (!isConfirmed) {
      return; // Si el usuario cancela, no hacer nada
    }

    setIsLoading(true);
    setMessage('');

    const jwtToken = localStorage.getItem('access_token');  // Usar el token guardado bajo 'access_token'
    if (!jwtToken) {
      setMessage('No se encontró el token de acceso. Por favor, inicia sesión.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/insert_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Token insertado con éxito');
      } else {
        setMessage(`Error: ${data.error}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {/* Advertencia para administradores */}
      <div style={styles.warningText}>
        <p><strong>Advertencia:</strong> Esta acción solo debe ser realizada por administradores. No realices cambios sin consultar primero.</p>
      </div>

      {/* Input y botón para actualizar el token */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Token de API"
          value={token}
          onChange={handleTokenChange}
          style={{
            padding: '8px',
            width: 'calc(100% - 110px)',
            marginRight: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
          }}
          disabled={isLoading}
        />
        <button
          style={{
            backgroundColor: '#ffc107',
            color: 'white',
            padding: '8px 15px',
            border: 'none',
            borderRadius: '5px',
            cursor: isLoading ? 'not-allowed' : 'pointer',
            fontWeight: 'bold',
          }}
          onClick={handleUpdateToken}
          disabled={isLoading || !token}
        >
          {isLoading ? 'Actualizando...' : 'Actualizar Token'}
        </button>
      </div>
      
      {/* Mensaje de resultado */}
      {message && (
        <div style={{ marginTop: '20px', textAlign: 'center', color: 'green' }}>
          {message}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '400px',
    margin: 'auto',
    border: '1px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f8f9fa',
    marginTop: '20px',
  },
  warningText: {
    backgroundColor: '#fff3cd', // Fondo amarillo pálido para la advertencia
    color: '#856404',           // Color de texto oscuro
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ffeeba',
    textAlign: 'center',
    fontWeight: 'bold',
  },
};

export default TokenUpdater;
