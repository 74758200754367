import React, { useState } from 'react';
import AutoScaleButton from '../colas/AutoScaleButton'; // Importa el componente AutoScaleButton

const QueueActionButtons = ({ fetchQueueStatus }) => {
  const [reloadingQueue, setReloadingQueue] = useState(false);

  const reloadMainQueue = async () => {
    if (window.confirm("¿Estás seguro de que quieres recargar la Main Queue? Esta acción tiene un costo asociado.")) {
      setReloadingQueue(true);
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/reload_main_queue/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const result = await response.json();
        console.log(result.message || result.error);
        fetchQueueStatus();
      } catch (error) {
        console.error("Error reloading main queue:", error);
      } finally {
        setTimeout(() => {
          setReloadingQueue(false);
        }, 30000);
      }
    }
  };

  const runPressScraperJob = async () => {
    if (window.confirm("¿Estás seguro de que quieres ejecutar el Press Scraper? Esta acción tiene un costo asociado.")) {
      setReloadingQueue(true);
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/run_press_scraper/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const result = await response.json();
        console.log(result.message || result.error);
        fetchQueueStatus();
      } catch (error) {
        console.error("Error running press scraper job:", error);
      } finally {
        setTimeout(() => {
          setReloadingQueue(false);
        }, 30000);
      }
    }
  };

  const triggerDeliveryPressDynoJob = async () => {
    if (window.confirm("¿Estás seguro de que quieres ejecutar el Delivery Press Dyno? Esta acción tiene un costo asociado.")) {
      setReloadingQueue(true);
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/delivery_bot/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        const result = await response.json();
        console.log(result.message || result.error);
        fetchQueueStatus();
      } catch (error) {
        console.error("Error running delivery press dyno job:", error);
      } finally {
        setTimeout(() => {
          setReloadingQueue(false);
        }, 30000);
      }
    }
  };

  const moveQueueMessages = async (sourceQueue, destinationQueue) => {
    setReloadingQueue(true);
    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/move_queue_messages/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ source_queue: sourceQueue, destination_queue: destinationQueue }),
      });

      const result = await response.json();
      console.log(result.message || result.error);
      fetchQueueStatus();
    } catch (error) {
      console.error("Error moving queue messages:", error);
    } finally {
      setTimeout(() => {
        setReloadingQueue(false);
      }, 30000);
    }
  };

  const handleMoveMessagesClick = () => {
    moveQueueMessages('pressreader_scraping_retries_queue', 'pressreader_scraping_queue');
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Acciones Disponibles</h3>

      <div className="queue-action-container">
        <button
          onClick={handleMoveMessagesClick}
          disabled={reloadingQueue}
          className="move-messages-button"
        >
          Mover Mensajes de Reintentos a Listos
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>

      <div className="jobs-warning-title">
        Lanzar los siguientes jobs tiene un coste asociado. Úsalos con responsabilidad.
      </div>

      <div className="queue-action-container">
        <button
          onClick={reloadMainQueue}
          disabled={reloadingQueue}
          className="heroku-button"
        >
          Recargar Main Queue
          <i className="fas fa-sync-alt"></i>
        </button>
        <button
          onClick={runPressScraperJob}
          disabled={reloadingQueue}
          className="heroku-button"
        >
          Ejecutar Press Scraper
          <i className="fas fa-hammer"></i>
        </button>
        <button
          onClick={triggerDeliveryPressDynoJob}
          disabled={reloadingQueue}
          className="heroku-button"
        >
          Publicar en Telegram
          <i className="fab fa-telegram-plane"></i>
        </button>
      </div>

      <AutoScaleButton />  {/* Incluye el componente AutoScaleButton */}
    </div>
  );
};

const styles = {
  container: {
    marginTop: '30px',
    paddingTop: '20px',
    borderTop: '2px solid #ddd',
  },
  title: {
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333',
    fontSize: '1.5rem',
  },
};

export default QueueActionButtons;
