import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ITEMS_PER_PAGE = 20;

const UserActivityByPeriod = ({ period }) => {
  const [rawData, setRawData] = useState([]); // Almacena todos los datos originales
  const [chartData, setChartData] = useState(null); // Almacena los datos para el gráfico actual
  const [filterText, setFilterText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    const fetchUserActivity = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`https://telegram-delivery-s3.herokuapp.com/api/user_activity/${period}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        const normalizedFilterText = filterText.replace(/_/g, ' ').toLowerCase();
        const filteredData = data.filter(item =>
          item._id.toLowerCase().replace(/_/g, ' ').includes(normalizedFilterText)
        );

        setRawData(filteredData); // Guarda los datos originales filtrados
        setMaxCount(Math.max(...filteredData.map(item => item.total_count))); // Calcula el valor máximo para el eje X
        setCurrentPage(0); // Reinicia la página cuando los datos cambian
      } catch (error) {
        console.error("Error fetching user activity data:", error);
      }
    };

    fetchUserActivity();
  }, [period, filterText]);

  useEffect(() => {
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const paginatedData = rawData.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const labels = paginatedData.map(item => item._id);
    const counts = paginatedData.map(item => item.total_count);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Clicks por Usuario',
          data: counts,
          backgroundColor: 'rgba(255, 99, 132, 0.6)',  // Color diferente para este gráfico
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          barThickness: 15,
        }
      ]
    });
  }, [rawData, currentPage]);

  const totalPages = Math.ceil(rawData.length / ITEMS_PER_PAGE);

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Actividad de Usuarios por {period}</h3>
      
      <div style={styles.controls}>
        <div style={styles.filterContainer}>
          <input
            type="text"
            placeholder="Filtrar por usuario..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={styles.filterInput}
          />
        </div>
      </div>

      {chartData ? (
        <>
          <div style={{ height: '600px' }}>
            <Bar 
              data={chartData} 
              options={{
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'right',
                  },
                  title: {
                    display: true,
                    text: 'Top Usuarios Filtrados',
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      autoSkip: false,
                      font: {
                        size: 12,
                      },
                      padding: 5,
                    }
                  },
                  x: {
                    beginAtZero: true,
                    min: 0,
                    max: maxCount, // Establece el valor máximo del eje X
                    ticks: {
                      font: {
                        size: 10,
                      },
                    },
                  },
                },
              }} 
            />
          </div>
          <div style={styles.pagination}>
            <button 
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} 
              disabled={currentPage === 0}
              style={styles.pageButton}
            >
              Anterior
            </button>
            <span style={styles.pageInfo}>Página {currentPage + 1} de {totalPages}</span>
            <button 
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages - 1))} 
              disabled={currentPage >= totalPages - 1}
              style={styles.pageButton}
            >
              Siguiente
            </button>
          </div>
        </>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    margin: '10px 0',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  filterInput: {
    padding: '5px 10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  pageButton: {
    padding: '5px 10px',
    fontSize: '1rem',
    borderRadius: '5px',
    border: '1px solid #007bff',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
    margin: '0 10px',
  },
  pageInfo: {
    fontSize: '1rem',
    margin: '0 10px',
  },
};

export default UserActivityByPeriod;
