import React from 'react';
import { FaDownload } from 'react-icons/fa';

const ExportCSVButton = ({ data }) => {
  const handleDownload = () => {
    const headers = [
      'Nombre Canónico',
      'CID',
      'Source',
      'Tipo',  // Añadir la columna Tipo
      'Activa',
      'L', 'M', 'X', 'J', 'V', 'S', 'D', // Días de la semana
      'Semana 1', 'Semana 2', 'Semana 3', 'Semana 4', 'Semana 5' // Semanas del mes
    ];

    const csvContent = [
      headers,
      ...data.map((row) => {
        const daysOfWeek = [1, 2, 3, 4, 5, 6, 7].map(day => row.weekdays.includes(day) ? 'True' : 'False');
        const weeksOfMonth = [1, 2, 3, 4, 5].map(week => row.nthweek.includes(week) ? 'True' : 'False');

        return [
          row.canonical_name,
          row.cid,
          row.source,
          row.type, // Añadir el valor del tipo (prensa o revista)
          row.is_active ? 'True' : 'False',
          ...daysOfWeek,
          ...weeksOfMonth
        ];
      })
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'publications.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button onClick={handleDownload} className="export-button">
      <FaDownload style={{ marginRight: '5px' }} />
      Descargar CSV
    </button>
  );
};

export default ExportCSVButton;
