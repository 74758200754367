import React, { useState, useEffect } from 'react';

const AutoScaleButton = () => {
  const [activeDynos, setActiveDynos] = useState(0);
  const [loadingDynos, setLoadingDynos] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActiveDynos = async () => {
      setLoadingDynos(true);
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/get_current_dynos/', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch active dynos');
        }

        const data = await response.json();
        setActiveDynos(data.current_dynos); // Aquí es donde aseguramos que se usa la propiedad correcta
      } catch (error) {
        setError('Failed to fetch the number of active dynos.');
      } finally {
        setLoadingDynos(false);
      }
    };

    fetchActiveDynos();
  }, []);

  const handleAutoScale = async () => {
    if (window.confirm("¿Estás seguro de que quieres iniciar el auto-escalado?")) {
      setLoadingDynos(true);
      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch('https://telegram-delivery-s3.herokuapp.com/api/auto_scale_dynos/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to auto-scale dynos');
        }

        // Refetch the number of active dynos after scaling
        const updatedResponse = await fetch('https://telegram-delivery-s3.herokuapp.com/api/get_current_dynos/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const updatedData = await updatedResponse.json();
        setActiveDynos(updatedData.current_dynos); // Aquí también usamos la propiedad correcta

        alert('Auto-escalado iniciado con éxito.');
      } catch (error) {
        setError('No se pudo auto-escalar los dynos. Intenta nuevamente más tarde.');
      } finally {
        setLoadingDynos(false);
      }
    }
  };

  return (
    <div style={styles.container}>
      <h4>Dynos Activos: {loadingDynos ? 'Cargando...' : activeDynos}</h4>
      {error && <p style={styles.error}>{error}</p>}
      <button
        onClick={handleAutoScale}
        disabled={loadingDynos}
        className="heroku-button"
      >
        {loadingDynos ? 'Auto-escalando...' : `Iniciar Auto-Escalado (${activeDynos} Dynos Activos)`}
      </button>
    </div>
  );
};

const styles = {
  container: {
    marginTop: '20px',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};

export default AutoScaleButton;
