import React from 'react';

const ModalEditCid = ({ isOpen, onClose, onSave, initialValue }) => {
  const [value, setValue] = React.useState(initialValue);
  const [error, setError] = React.useState(''); // Estado para manejar el mensaje de error

  const handleSave = () => {
    if (!value.trim()) { // Verifica que el CID no esté vacío o solo tenga espacios en blanco
      setError('El CID no puede estar vacío');
      return;
    }
    setError(''); // Resetea el error si el CID es válido
    onSave(value);
  };

  if (!isOpen) return null;

  return (
    <>
      <style>
        {`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-content {
            background: white;
            padding: 20px;
            border-radius: 8px;
            max-width: 500px;
            width: 100%;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            position: relative;
          }

          .modal-content h2 {
            margin-bottom: 20px;
            font-size: 1.5rem;
          }

          .modal-content p {
            margin-bottom: 20px;
            font-size: 1rem;
            color: #555;
          }

          .modal-content input {
            padding: 10px;
            width: calc(100% - 20px);
            font-size: 1rem;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-bottom: 20px;
          }

          .modal-content .info-box {
            background-color: #f5f5f5;
            border-left: 5px solid #007bff;
            padding: 10px;
            border-radius: 4px;
            text-align: left;
            font-size: 0.9rem;
            margin-bottom: 20px;
          }

          .modal-content .info-box i {
            margin-right: 5px;
            color: #007bff;
            font-size: 1.2rem;
            vertical-align: middle;
          }

          .modal-content .modal-actions {
            display: flex;
            justify-content: space-between;
          }

          .modal-content .modal-actions button {
            padding: 10px 20px;
            border-radius: 4px;
            font-size: 1rem;
            cursor: pointer;
            border: none;
          }

          .modal-content .modal-actions .save-button {
            background-color: #007bff;
            color: white;
          }

          .modal-content .modal-actions .cancel-button {
            background-color: #dc3545;
            color: white;
          }

          .error-message {
            color: red;
            font-size: 0.9rem;
            margin-bottom: 20px;
          }
        `}
      </style>
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Editar CID</h2>
          <p>CID Actual: <strong>{initialValue}</strong></p>
          <p>Por favor, ingresa el nuevo CID para esta publicación.</p>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {error && <p className="error-message">{error}</p>}
          <div className="info-box">
            <i className="icon-info"></i>
            El CID es el código interno que se usa durante el scraping, solo debe editarse tras verificar que este identificador ha cambiado en la fuente.
          </div>
          <div className="modal-actions">
            <button className="save-button" onClick={handleSave}>Guardar</button>
            <button className="cancel-button" onClick={onClose}>Cancelar</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditCid;
