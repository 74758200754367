import React, { useState } from 'react';
import ClicksByPeriod from './ClicksByPeriod';
import UserActivity from './UserActivity';

const UserBehaviorAnalytics = () => {
  const [period, setPeriod] = useState('day');

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Análisis del Comportamiento de Usuarios</h2>
      <div style={styles.periodSelector}>
        <label>
          Seleccionar período: 
          <select value={period} onChange={(e) => setPeriod(e.target.value)} style={styles.select}>
            <option value="day">Hoy</option>
            <option value="week">Esta semana</option>
            <option value="month">Este mes</option>
          </select>
        </label>
      </div>
      <ClicksByPeriod period={period} />
      <UserActivity period={period} />
    </div>
  );
};

const styles = {
  container: {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#f8f9fa',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '1.5rem',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  periodSelector: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  select: {
    marginLeft: '10px',
    padding: '5px',
    fontSize: '1rem',
  },
};

export default UserBehaviorAnalytics;
