import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

const QueuePeekScrapers = ({ queueName, maxMessages = 10 }) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false); 
    const [showErrorDetails, setShowErrorDetails] = useState(false); 

    const fetchMessages = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://telegram-delivery-s3.herokuapp.com/api/peek_messages?queue_name=${queueName}&max_messages=${maxMessages}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            setMessages(data);
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    const toggleExpand = () => {
        if (!isExpanded) {
            fetchMessages();
        }
        setIsExpanded(!isExpanded);
    };

    const toggleErrorDetails = () => {
        setShowErrorDetails(!showErrorDetails);
    };

    return (
        <div style={styles.container}>
            <div style={styles.header} onClick={toggleExpand}>
                <span style={styles.separatorText}>{`Mensajes en la ${queueName}`}</span>
                <span style={styles.icon}>
                    {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                </span>
            </div>

            {isExpanded && (
                <div style={styles.content}>
                    {loading ? (
                        <p>Cargando mensajes...</p>
                    ) : error ? (
                        <div>
                            <p>Error al cargar los mensajes: {error}</p>
                            <p style={styles.warningText}>
                                Mostrar los mensajes de la cola crea conexiones al broker de mensajes. Actualmente, hay muchos componentes utilizando el broker. Intenta evitar usar esta función durante las horas punta.
                            </p>
                            <button onClick={toggleErrorDetails} style={styles.detailsButton}>
                                {showErrorDetails ? "Ocultar detalles" : "Ver más detalles"}
                            </button>
                            {showErrorDetails && (
                                <pre style={styles.errorDetails}>{error}</pre>
                            )}
                        </div>
                    ) : messages.length === 0 ? (
                        <p style={styles.noMessagesText}>No hay mensajes en la cola.</p>
                    ) : (
                        <ul style={styles.messageList}>
                            {messages.map((message, index) => (
                                <li key={index} style={styles.messageItem}>
                                    <p><strong>Canonical Name:</strong> {message.canonical_name}</p>
                                    <p><strong>CID:</strong> {message.cid}</p>
                                    <p><strong>Fecha:</strong> {message.date}</p>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        marginTop: '20px',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '10px',
        backgroundColor: '#f8f9fa',
        cursor: 'pointer',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    separatorText: {
        color: '#333',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    icon: {
        marginLeft: '10px',
    },
    content: {
        marginTop: '15px',
    },
    noMessagesText: {
        color: '#555',
        textAlign: 'center',
    },
    warningText: {
        color: '#cc0000',
        marginTop: '10px',
    },
    detailsButton: {
        marginTop: '10px',
        padding: '5px 10px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    errorDetails: {
        marginTop: '10px',
        backgroundColor: '#f8f8f8',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ddd',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
    messageList: {
        listStyleType: 'none',
        padding: 0,
    },
    messageItem: {
        padding: '10px',
        borderBottom: '1px solid #ddd',
        marginBottom: '10px',
    },
};

export default QueuePeekScrapers;
