import React, { useState, useEffect } from 'react';
import './TodayPublications.css';

const TodayPublications = () => {
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' }); // Estado para la ordenación


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchTodayData = async () => {
      try {
        const token = localStorage.getItem('access_token');

        const [pubResponse, clicksResponse] = await Promise.all([
          fetch('https://telegram-delivery-s3.herokuapp.com/api/today_publications', {
            headers: { Authorization: `Bearer ${token}` },
          }),
          fetch('https://telegram-delivery-s3.herokuapp.com/api/today_clicks', {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        if (!pubResponse.ok || !clicksResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const publicationsData = await pubResponse.json();
        const clicksData = await clicksResponse.json();

        // Crear un mapa de clicks por canonical_name para fácil acceso
        const clicksMap = new Map();
        clicksData.forEach(click => {
          clicksMap.set(click.canonical_name, click);
        });

        // Combinar publicaciones y clicks
        const combined = publicationsData.map(pub => {
          const matchingClick = clicksMap.get(pub.canonical_name);
          return {
            ...pub,
            delivered_clicks: matchingClick ? matchingClick.delivered_count : 0,
            non_delivered_clicks: matchingClick ? matchingClick.not_delivered_count : 0,
            last_click_time: matchingClick ? matchingClick.last_click_time : null,
            last_click_user: matchingClick ? matchingClick.last_click_user : null,
            total_clicks: matchingClick ? matchingClick.total_count : 0,
          };
        });

        // Agregar las entradas de clicks que no tienen una publicación correspondiente
        clicksData.forEach(click => {
          if (!publicationsData.some(pub => pub.canonical_name === click.canonical_name)) {
            combined.push({
              canonical_name: click.canonical_name,
              cid: 'N/A',
              run_date: null,
              delivered_clicks: click.delivered_count,
              non_delivered_clicks: click.not_delivered_count,
              last_click_time: click.last_click_time,
              last_click_user: click.last_click_user,
              total_clicks: click.total_count,
            });
          }
        });

        setCombinedData(combined);
      } catch (error) {
        setError('No se pudieron cargar las publicaciones de hoy. Intenta nuevamente más tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchTodayData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { timeZone: 'Europe/Madrid' });
  };

  const formatTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'Europe/Madrid',
    });
  };

  const formatTimeAgo = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now - date;
    const diffHrs = Math.floor(diffMs / 3600000);
    const diffMins = Math.floor((diffMs % 3600000) / 60000);

    if (diffHrs > 0) {
      return `hace ${diffHrs} horas ${diffMins} minutos`;
    } else {
      return `hace ${diffMins} minutos`;
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...combinedData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setCombinedData(sortedData);
  };

  const filteredData = combinedData.filter(pub =>
    pub.canonical_name.toLowerCase().includes(filter)
  );

  return (
    <div className="container">
      <h1 className="title">Publicaciones de Hoy</h1>

      {loading ? (
        <div className="loading">Cargando publicaciones...</div>
      ) : error ? (
        <div className="error-container">
          <h2 className="error-message">Lo sentimos...</h2>
          <p className="error-message">{error}</p>
        </div>
      ) : (
        <>
          <div className="counter-container">
            <span className="counter">{filteredData.length}</span>
          </div>

          <div className="filter-container">
            <input
              type="text"
              placeholder="Filtrar por nombre..."
              value={filter}
              onChange={handleFilterChange}
              className="filter-input"
            />
          </div>

          {filteredData.length === 0 ? (
            <p className="no-publications">No hay publicaciones que coincidan con el filtro.</p>
          ) : (
            <>
              {isMobile ? (
                <ul className="list">
                  {filteredData.map((pub, index) => (
                    <li key={index} className="list-item">
                      <h3 style={{ marginBottom: '5px' }}>{pub.canonical_name}</h3>
                      <p><strong>CID:</strong> {pub.cid}</p>
                      <p><strong>Fecha:</strong> {formatDate(pub.run_date)} {formatTime(pub.run_date)}</p>
                      <p><strong>Hace:</strong> {formatTimeAgo(pub.run_date)}</p>
                      <p><strong>Clicks Entregados:</strong> {pub.delivered_clicks}</p>
                      <p><strong>Clicks No Entregados:</strong> {pub.non_delivered_clicks}</p>
                      <p><strong>Total de Clicks:</strong> {pub.total_clicks}</p>
                      <p><strong>Último Click:</strong> {pub.last_click_time ? `${formatDate(pub.last_click_time)} ${formatTime(pub.last_click_time)}` : 'N/A'}</p>
                      <p><strong>Último Usuario:</strong> {pub.last_click_user || 'N/A'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="table-header" onClick={() => handleSort('canonical_name')}>Nombre</th>
                      <th className="table-header" onClick={() => handleSort('cid')}>CID</th>
                      <th className="table-header" onClick={() => handleSort('run_date')}>Fecha</th>
                      <th className="table-header" onClick={() => handleSort('run_date')}>Hace</th>
                      <th className="table-header" onClick={() => handleSort('delivered_clicks')}>Clicks Entregados</th>
                      <th className="table-header" onClick={() => handleSort('non_delivered_clicks')}>Clicks No Entregados</th>
                      <th className="table-header" onClick={() => handleSort('total_clicks')}>Total de Clicks</th>
                      <th className="table-header" onClick={() => handleSort('last_click_time')}>Último Click</th>
                      <th className="table-header" onClick={() => handleSort('last_click_user')}>Último Usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((pub, index) => (
                      <tr key={index}>
                        <td className="table-cell">{pub.canonical_name}</td>
                        <td className="table-cell">{pub.cid}</td>
                        <td className="table-cell">{`${formatDate(pub.run_date)} ${formatTime(pub.run_date)}`}</td>
                        <td className="table-cell">{formatTimeAgo(pub.run_date)}</td>
                        <td className="table-cell">{pub.delivered_clicks}</td>
                        <td className="table-cell">{pub.non_delivered_clicks}</td>
                        <td className="table-cell">{pub.total_clicks}</td>
                        <td className="table-cell">{pub.last_click_time ? `${formatDate(pub.last_click_time)} ${formatTime(pub.last_click_time)}` : 'N/A'}</td>
                        <td className="table-cell">{pub.last_click_user || 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TodayPublications;
